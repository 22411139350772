import { ImpressionTrackingObject, MainLink } from '../../types';
import { getIndexPosition, Tracking } from '../hooks/use-tracking';
import { getItemTitle, getTrackingLabelDisplay } from './utils';

/**
 * Tracks an impression event.
 *
 * @param {Tracking} tracking - The tracking service instance.
 * @param {ImpressionTrackingObject} trackingObject - The tracking object to be tracked.
 * @param {MainLink[]} [subLinks] - The relatedElements to be tracked.
 */
export const trackImpressionEvent = (
  tracking: Tracking,
  trackingObject: ImpressionTrackingObject,
  subLinks?: MainLink[],
): void => {
  tracking.impression({
    elementName: '',
    label: trackingObject.label,
    clickID: '',
    targetURL: '',
    value: trackingObject.value,
    pos: '',
    relatedElements: relatedElements(subLinks),
  });
};

/**
 * Tracks the toggle event for in-page navigation.
 *
 * @param {Tracking} tracking - The tracking service instance.
 * @param {InternalLink} [parentPageInpageNavigation] - The parent page in-page navigation link.
 * @param {ActiveLinks} [topLevelActiveLink] - The active link at the top level.
 * @param {ActiveLinks} [sameLevelActiveLink] - The active link at the same level.
 * @param {SubNavActive} [subNavActive] - The active sub-navigation state.
 * @param {MainLink[]} [subLinks] - The sub-links to be tracked.
 */
export const trackToggleEvent = (
  tracking: Tracking,
  isActiveSubNav: boolean,
  labelArray: string[],
  targetURL: string,
  index: number,
  subLinks?: MainLink[],
): void => {
  const label = getTrackingLabelDisplay(labelArray);

  tracking.toggleNavigation({
    elementName: 'text link',
    label,
    clickID: '',
    targetURL,
    value: '2 sub levels',
    pos: `${getIndexPosition(index)}`,
    eventName: `inpage navigation - ${isActiveSubNav ? 'open' : 'close'} sub navigation`,
    relatedElements: relatedElements(subLinks),
  });
};

/**
 * Constructs an array of related elements for tracking purposes.
 *
 * @param {MainLink[]} [links] - The links to be transformed into related elements.
 * @returns {Array<{ elementName: string; elementValue: string }>} An array of related element objects.
 */
export const relatedElements = (links?: MainLink[]) => {
  const elements =
    links?.map(({ fields }) => ({
      elementName: 'navigation item',
      elementValue: getItemTitle(fields),
    })) || [];
  return elements;
};
