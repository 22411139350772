/* eslint-disable max-len */
import { useMemo } from 'react';
import { ActiveLinks, MainLink, SameLevelInpageNavigation, SubNavActive } from '../../../types';
import { hasSubLinks, isSecondaryLevelFromUrl } from '../../utils/utils';

/**
 * Custom hook to retrieve sub links based on the current navigation context.
 *
 * @param {boolean} visible - Indicates if the navigation is visible.
 * @param {SameLevelInpageNavigation[] | undefined} sameLevelInpageNavigation - Array of same level inpage navigation items.
 * @param {ActiveLinks | undefined} topLevelActiveLink - The active link at the top level.
 * @param {ActiveLinks | undefined} sameLevelActiveLink - The active link at the same level.
 * @param {ActiveLinks | undefined} secondLevelActiveLink - The active link at the second level.
 * @param {SubNavActive | undefined} subNavActive - The active sub-navigation state.
 * @returns {{ subLinks: MainLink[] }} - An object containing an array of sub links.
 */
export function useSubLinks(
  visible: boolean,
  sameLevelInpageNavigation: SameLevelInpageNavigation[] | undefined,
  topLevelActiveLink: ActiveLinks | undefined,
  sameLevelActiveLink: ActiveLinks | undefined,
  secondLevelActiveLink: ActiveLinks | undefined,
  subNavActive: SubNavActive | undefined,
): { subLinks: MainLink[] } {
  const indx: number = topLevelActiveLink
    ? topLevelActiveLink.index
    : (sameLevelActiveLink?.index as number) || 0;
  const isSubLevelUrl: boolean = isSecondaryLevelFromUrl(secondLevelActiveLink);

  const subLinks: MainLink[] = useMemo(() => {
    // load sublinks from match url and secondLevelActiveLink context
    if (secondLevelActiveLink && isSubLevelUrl) {
      const i = indx >= 0 ? indx : Number(secondLevelActiveLink.index);
      return hasSubLinks(i, sameLevelInpageNavigation);
    }
    return hasSubLinks(indx, sameLevelInpageNavigation);
  }, [sameLevelInpageNavigation, visible, secondLevelActiveLink, subNavActive, indx]);

  return { subLinks };
}
