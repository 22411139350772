/* eslint-disable react/jsx-no-constructed-context-values */
/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { GlobalStyles, ThemeProvider, audiLightTheme, audiDarkTheme } from '@audi/audi-ui-react';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import InpageNav from './components/inpageNav/InpageNav';
import { FalconContent } from './contentMapping';
import { InPageNavConfig, InternalLink } from '../types';
import { InPageNavProvider, TrackingServiceContext } from './context';

interface FeatureAppProps {
  content: FalconContent;
  trackingService: TrackingServiceV2;
}

const FeatureApp: React.FC<FeatureAppProps> = (props: FeatureAppProps) => {
  const { content, trackingService } = props;

  const state: InPageNavConfig = {
    parentPageInpageNavigation: content?.fields
      ?.parentPageInpageNavigation as unknown as InternalLink,
    sameLevelInpageNavigation: content?.fields?.sameLevelInpageNavigation || [],
    subLevelInpageNavigation: content?.fields?.subLevelInpageNavigation || [],
    accordionLabel: content?.fields?.accordionLabel || '',
    prioprityNavActive: {
      isDesktop: false,
      interaction: 0,
    },
    uiTheme: content?.fields?.uiTheme || 'light',
    removeStickiness: content?.fields?.removeStickiness || false,
  };

  return (
    <ThemeProvider theme={state.uiTheme === 'dark' ? audiDarkTheme : audiLightTheme}>
      <InPageNavProvider inPageNavValue={state}>
        <TrackingServiceContext.Provider value={{ trackingService }}>
          <GlobalStyles />
          <InpageNav />
        </TrackingServiceContext.Provider>
      </InPageNavProvider>
    </ThemeProvider>
  );
};

export default FeatureApp;
