/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useCallback, useContext } from 'react';
import NavBarWrapper from './NavBarWrapper';
import NavItems from './NavItems';
import { TrackingServiceContext, useInPageDispatch, useInPageNavContext } from '../../context';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';
import { ActiveLinks } from '../../../types';
import OverViewLabel from './OverViewLabel';
import { useTracking } from '../../hooks/use-tracking';
import { useSubLinks } from '../../hooks/use-sub-links';

interface IProps {
  visible: boolean;
}

const SecondLevelNavDesktop = (props: IProps) => {
  const dispatch = useInPageDispatch();
  const { visible } = props;
  const { trackingService } = useContext(TrackingServiceContext);
  const tracking = useTracking(trackingService);

  const {
    sameLevelInpageNavigation,
    topLevelActiveLink,
    sameLevelActiveLink,
    secondLevelActiveLink,
    subNavActive,
  } = useInPageNavContext();
  const { subLinks } = useSubLinks(
    visible,
    sameLevelInpageNavigation,
    topLevelActiveLink,
    sameLevelActiveLink,
    secondLevelActiveLink,
    subNavActive,
  );

  const handleSelectedItem = useCallback(
    ({ id, index, text, href, target }: ActiveLinks) => {
      dispatch?.({
        type: INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK,
        payload: {
          secondLevelActiveLink: { id, index, text, href, target, overviewLinkSelected: false },
        },
      });
      // tracking event from second level navigation links, excludes overview links
      const topLevelItem = topLevelActiveLink || sameLevelActiveLink;
      tracking?.navigationItemClick({
        elementName: 'text link',
        label: `${topLevelItem?.text} > ${text}`,
        clickID: '',
        targetURL: href || '',
        value: `2 sub level`,
        pos: `${topLevelItem?.index}-${index}`,
        isSubNavigation: true,
        eventName: `inpage navigation - click on link`,
      });
    },
    [dispatch, topLevelActiveLink, sameLevelActiveLink],
  );

  return (
    subLinks?.length > 0 && (
      <NavBarWrapper visible={visible} isSecondaryLevel>
        <OverViewLabel isSecondaryLevel />
        {subLinks?.map((item, i) => {
          const { fields } = item;
          return (
            <NavItems
              // eslint-disable-next-line react/no-array-index-key
              key={`nav-item-${i}`}
              fields={fields}
              index={i}
              hasParentElement={false}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              handleSelectedItem={handleSelectedItem}
              fieldsLength={subLinks?.length}
              isSecondaryLevel
            />
          );
        })}
      </NavBarWrapper>
    )
  );
};

export default SecondLevelNavDesktop;
