import { InPageNavConfig } from '../../types';

export type InPageNavAction = {
  type: string;
  payload: InPageNavConfig;
};

export const INPAGENAV_ACTIONS = {
  SAME_LEVEL_ACTIVE_LINK: 'SAME_LEVEL_ACTIVE_LINK',
  TOP_LEVEL_ACTIVE_LINK: 'TOP_LEVEL_ACTIVE_LINK',
  SUB_LEVEL_ACTIVE_LINK: 'SUB_LEVEL_ACTIVE_LINK',
  TOP_ACTIVE_LINKS: 'TOP_ACTIVE_LINKS',
  IS_LOADED_FROM_URL: 'IS_LOADED_FROM_URL',
  TOGGLE_VISIBLE: 'TOGGLE_VISIBLE',
  PRIORITY_NAV_LOADED: 'PRIORITY_NAV_LOADED',
  PRIORITY_NAV_ACTIVE: 'PRIORITY_NAV_ACTIVE',
  IMPRESSION_TRACKING: 'IMPRESSION_TRACKING',
};

export const inPageNavReducer = (
  state: InPageNavConfig,
  action: InPageNavAction,
): InPageNavConfig => {
  switch (action.type) {
    case INPAGENAV_ACTIONS.SAME_LEVEL_ACTIVE_LINK:
    case INPAGENAV_ACTIONS.TOP_LEVEL_ACTIVE_LINK:
    case INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK:
      return { ...state, ...action.payload };
    case INPAGENAV_ACTIONS.TOP_ACTIVE_LINKS:
      return { ...state, topActiveLinks: action.payload.topActiveLinks };
    case INPAGENAV_ACTIONS.IS_LOADED_FROM_URL:
      return { ...state, isLoadedFromUrl: action.payload.isLoadedFromUrl };
    case INPAGENAV_ACTIONS.TOGGLE_VISIBLE:
      return { ...state, toggleVisible: action.payload.toggleVisible };
    case INPAGENAV_ACTIONS.PRIORITY_NAV_LOADED:
      return { ...state, priorityNavLoaded: action.payload.priorityNavLoaded };
    case INPAGENAV_ACTIONS.PRIORITY_NAV_ACTIVE:
      return { ...state, prioprityNavActive: action.payload.prioprityNavActive };
    case INPAGENAV_ACTIONS.IMPRESSION_TRACKING:
      return { ...state, impressionTracking: action.payload.impressionTracking };
    default:
      return state;
  }
};
